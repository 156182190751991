import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from 'styled-components';
import { useTranslation } from 'react-i18next';
import rtl from 'jss-rtl';
import { create } from 'jss';
import jssPreset from '@mui/styles/jssPreset';
import StylesProvider from '@mui/styles/StylesProvider';
import createTheme from '@mui/material/styles/createTheme';
import MuiThemeProvider from '@mui/material/styles/ThemeProvider';
import { createRoot } from 'react-dom/client';
import './i18n';

import { unregister } from './serviceWorker';

unregister();
if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const getTheme = language => {
  return createTheme({
    colorSchemes: {
      dark: false
    },
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true
        }
      },
      MuiPaper: {
        styleOverrides: { root: { backgroundImage: 'unset' } }
      }
    },
    shape: {
      borderRadius: 2
    },
    typography: {
      fontFamily: language === 'he' ? 'Rubik' : 'Montserrat',
      body1: {
        fontSize: '14px'
      },
      h5: {
        fontWeight: 600
      },
      h1: {
        fontSize: '3rem'
      }
    },
    direction: language === 'he' ? 'rtl' : 'ltr',
    palette: {
      mode: 'light',
      primary: {
        light: '#15596B',
        main: '#16556A',
        dark: '#071956',
        contrastText: '#fff',
        mainGradient: 'linear-gradient(90deg, rgba(0,0,0,1) 43%, rgba(20,194,169,1) 100%);'
      },
      secondary: {
        main: '#14C2A9',
        headerFooterColor: '#16596B'
      },
      common: {
        main: '#15596B'
      },
      info: {
        main: '#bac778'
      },
      success: {
        main: '#bac778'
      },
      warning: {
        main: '#bac778'
      }
    },

    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1200,
        xl: 1536
      },
      get down() {
        return key => `@media (max-width:${this.values[key] - 0.5}px)`;
      },
      get up() {
        return key => `@media (min-width:${this.values[key] + 0.5}px)`;
      },
      get between() {
        return (keyA, keyB) =>
          `@media (min-width:${this.values[keyA]}px) and (max-width:${this.values[keyB] - 0.5}px)`;
      }
    },

    overrides: {
      MuiTooltip: {
        tooltip: {
          backgroundColor: 'rgba(97, 97, 97, 1)',
          fontSize: '1em'
        }
      }
    }
  });
};
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const MyApp = () => {
  const { i18n } = useTranslation();
  const theme = getTheme(i18n.languages[0]);
  process.env.NODE_ENV === 'development' && console.log(theme);
  document.getElementsByTagName('html')[0].setAttribute('dir', i18n.languages[0] === 'he' ? 'rtl' : 'ltr');

  return (
    <StylesProvider jss={jss}>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
};
const rootElement = createRoot(document.getElementById('root'));
// const defaultOptions = {
//   loop: true,
//   autoplay: true,
//   animationData,
//   rendererSettings: {
//     preserveAspectRatio: 'xMidYMid slice'
//   }
// };

rootElement.render(<MyApp />, rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
